import './ecommerce.css'
import boletaImg from '../../../../Others/ecommerce-dimasoft.png'
import { FaEnvelope, FaMapMarkerAlt, FaRegClock } from 'react-icons/fa'
import { Footer} from '../../Footer/Footer'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
import { FormContact} from '../../Contacto/Contacto'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../../MetaTags/TitleDescriptions';

export function Ecommerce(){

    return <div className='containerEcommerce'>
        <TitleDescription title='E-Commerce, Tienda Online - Dimasoft' description='Software Ecommerce Chile cuenta con integracion de WebPay Plus - Transbank y Mercado Pago. 100% integrado al ERP - Gestion Comercial Dimasoft v7.1'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <div className='ecommerce'>
            <h1>E-Commerce Dimasoft</h1>
            <div className='line gradient line-medium'></div>
            <div className='ecommerce-content'>
                <div className='ecommerce-content-left'>
                    <FormContact/>
                </div>
                <div className='ecommerce-content-right'>
                    <h3>Características</h3>
                    <ul data-aos="fade-left">
                        <li>Integrado 100% al ERP – Gestión Comercial v7.1 Dimasoft</li>
                        <li>Sincronización de los Productos, Stock, Precios y Clientes con el ERP</li>
                        <li>Generación automática de la Nota de Pedido cuando un cliente opera desde el E-Commerce</li>
                        <li>No requiere mayor intervención a su actual página Web</li>
                        <li>Integración con WebPay Plus de Transbank</li>
                        <li>Integración con Mercado Pago</li>
                        <li>Capacitación</li>
                    </ul>
                    <img src={boletaImg} alt="ecommerce" data-aos="zoom-in"/>
                    <h3>Informacíon de contacto</h3>
                    <p>Si tiene alguna duda o consulta, contáctenos,</p>
                    <p>+56 22 205 1018</p>
                    <p><FaMapMarkerAlt/> Pocuro #2378, Of. 201, Providencia, Santiago</p>
                    <p><FaEnvelope/> info@dimasoft.cl</p>
                    <br />
                    <h3>Horario de atención</h3>
                    <p><FaRegClock/> Lunes a viernes - 08:30 a 18:30</p>
                </div>
            </div>
        </div>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}