import './numbers.css'
import { useState, useEffect, useRef } from 'react';

export function Numbers(){

    const data = [
        {
            number: 30,
            title: 'Años en el mercado'
        },
        {
            number: 7000,
            title: 'Clientes'
        },
        {
            number: 45000,
            title: 'Usuarios'
        },
        {
            number: 1800,
            title: 'Sucursales Clientes'
        }
    ]

    return <div className="containerNumbers">
        {data.map((item, index) => 
            <div className="card-number" key={index} data-aos="fade-up">
                <Counter max={item.number}/>
                <p className='cn-title'>{item.title}</p>
            </div>)
        }
    </div>
}

const Counter = ({max}) => {
    const [count, setCount] = useState(0);
    const [hasStarted, setHasStarted] = useState(false);
    const counterRef = useRef(null);
  
    useEffect(() => {
        const observer = new IntersectionObserver((entries)=>{
            const entry = entries[0]
            if (entry.isIntersecting && !hasStarted){
                setHasStarted(true)
            }
        },{threshold: 0.8,}
        )
    
        if (counterRef.current) {
            observer.observe(counterRef.current)
        }
    
        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current)
            }
        }
    },[hasStarted])

    let total_time = 2000
    let add = Math.round(max/(total_time/50))
    useEffect(() => {
        if (hasStarted) {
            const interval = setInterval(() => {
            setCount((prevCount) => {
                if (prevCount >= max) {
                    clearInterval(interval)
                    return prevCount
                }
                return prevCount + add
            })
            }, 50)
    
            return () => clearInterval(interval)
        }
    },[hasStarted])
  
    return <p ref={counterRef} className='cn-number'><span>+</span> {count}</p>
}