import './contableauditoria.css'
import { Link, ScrollRestoration } from "react-router-dom";
import { Footer} from '../../Footer/Footer'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
import contabilidadImg from '../../../../Others/contabilidad-dimasoft.png'

import { TitleDescription } from '../../MetaTags/TitleDescriptions';


export function ContableAuditoriaR(){
    return <div className='containerContAudi'>
        <TitleDescription title='Servicio de Contabilidad, Auditoria y Administracion General - Dimasoft' description='En nuestro equipo contamos con especialistas que los ayudaran a maximizar su rentabilidad.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <h1>Servicio Contable y Auditoria</h1>
        <ContableAuditoria/>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}
export function ContableAuditoria(){
    return <div className='contAudi'>
        <div className='line gradient line-small' data-aos="fade-right"></div><br />
        <div className='g-content-flex'>
            <div className='g-content-60' data-aos="fade-right">
                <p>En nuestro equipo contamos con profesionales de basta trayectoria y experiencia laboral, los que se han especializado en las materias propias de las áreas señaladas, junto con la utilización de modelos y herramientas existentes, acordes a las necesidades de cada cliente, con el fin de apoyar a que estos puedan maximizar su rentabilidad, crecimiento y competitividad en el mercado en que participan.</p>
                <p>Nuestro servicio contempla la externalización de la información Contable, Tributaria y Laboral de las empresas, como también la asesoría permanente o esporádica en ellas.</p>
            </div>
            <div className='g-content-40'>
                <img src={contabilidadImg} alt="ayudaTecnicaImg" />
            </div>
        </div><br /><br />

        <div className='g-content'>
            <div className='g-content-flex'>
                <div className='g-content-50' data-aos="fade-right">
                    <h3>ASESORIAS CONTABLES:</h3>
                    <ul>
                        <li>ADMINISTRACIÓN GENERAL DE LA CONTABILIDAD</li>
                        <li>APERTURA E INICIO DE ACTIVIDADES ANTE S.I.I.</li>
                        <li>CONTABILIDADES ATRASADAS</li>
                        <li>BALANCES Y ESTADOS DE RESULTADO</li>
                    </ul>
                </div>
                <div className='g-content-50' data-aos="fade-right">
                    <h3>ASESORIAS TRIBUTARIAS:</h3>
                    <ul>
                        <li>DECLARACIÓN DE IMPUESTOS MENSUALES</li>
                        <li>DECLARACIÓN DE IMPUESTO RENTA</li>
                        <li>CITACIONES ANTE EL S.I.I.</li>
                        <li>REFORMA TRIBUTARIA</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className='g-content'>
            <div className='g-content-flex'>
                <div className='g-content-50' data-aos="fade-right">
                    <h3>ASESORIAS LABORALES:</h3>
                    <ul>
                        <li>CONFECCIÓN DE LIQUIDACIONES DE SUELDO</li>
                        <li>CONFECCIÓN DE CONTRATOS, FINIQUITOS E IMPOSICIONES</li>
                        <li>CONFECCION REGLAMENTO INTERNO DE ORDEN, HIGIENE Y SEGURIDAD</li>
                    </ul>
                </div>
                <div className='g-content-50' data-aos="fade-right">
                    <h3>AUDITORIA:</h3>
                    <ul>
                        <li>AUDITORIA FINANCIERA</li>
                        <li>AUDITORIA TRIBUTARIA</li>
                        <li>EVALUACIÓN DE CONTROL INTERNO</li>
                        <li>PROCESOS ADMINISTRATIVOS</li>
                    </ul>
                </div>
            </div>
        </div>

        <div className='g-content content-center'>
            <h2 className='span-contact'>Contáctenos</h2>
            <div className='line gradient line-small' data-aos="fade-right"></div>
            <p>Solicite más información, conversemos de su negocio...</p>
            <Link to="/contacto"><button className='secondary-button'>Contáctenos</button></Link>
        </div>
    </div>
}