import './clientes.css'
// import { Footer} from '../Footer/Footer'
import { FirstHeader, SecondHeader} from '../Header/Headers'
import { LeftButtons} from '../LeftButtons/ContactButtons'
import * as allClients from '../../../Clientes/clientsImg'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../MetaTags/TitleDescriptions';

const clientes = [
    {
        image:allClients['fernando_bravo'],
        title: 'Ferreteria Fernando Bravo',
        address: 'Santiago, RM'
    },
    {
        image:allClients['ferreteria_aurora'],
        title: 'Ferreteria Aurora',
        address: 'La Granja, RM'
    },
    {
        image:allClients['la_estrella'],
        title: 'Barracas La Estrella',
        address: 'Pudahuel, RM'
    },
    {
        image:allClients['abaflex'],
        title: 'Abaflex Ltda.',
        address: 'Ñuñoa, RM'
    },
    {
        image:allClients['alb_minerals'],
        title: 'ALB Minerals Spa.',
        address: 'San Miguel, RM'
    },
    {
        image:allClients['america_dvd'],
        title: 'America DVD Ltda.',
        address: 'Viña del Mar, Viña del Mar'
    },
    {
        image:allClients['armada'],
        title: 'Armada de Chile',
        address: 'Cabo de Hornos, Puerto Williams'
    },
    {
        image:allClients['floral'],
        title: 'Arte y Decoracion Floral Ltda.',
        address: 'Las Condes, RM'
    },
    {
        image:allClients['atecmin'],
        title: 'Atecmin Ltda.',
        address: 'Ñuñoa, RM'
    },
    {
        image:allClients['authievre'],
        title: 'Authievre Motors Spa.',
        address: 'Santiago, RM'
    },
    {
        image:allClients['authievre'],
        title: 'Authievre Motors Spa.',
        address: 'Heuchuraba, RM'
    },
    {
        image:allClients['authievre'],
        title: 'Authievre Motors Spa.',
        address: 'Providencia, RM'
    },
    {
        image:allClients['burger'],
        title: 'Gruas Bruger EIRL.',
        address: 'Quilicura, RM'
    },
    {
        image:allClients['decorata'],
        title: 'Decoratta Ltda.',
        address: 'Las Condes, RM'
    },
    {
        image:allClients['dimar'],
        title: 'Dimar Lobos Ltda.',
        address: 'Quinta Normal, RM'
    },
    {
        image:allClients['ballerines'],
        title: 'Imp. Bernardo Ballerines Spa.',
        address: 'Santiago, RM'
    },
    {
        image:allClients['brinks'],
        title: 'Brinks Chile S.A.',
        address: 'Independencia, RM'
    },
    {
        image:allClients['bell'],
        title: 'Bell Telephone Ltda.',
        address: 'Valparaiso, Valparaiso'
    },
    {
        image:allClients['ecaso'],
        title: 'Automotores Ecaso S.A.',
        address: 'Talca, Talca'
    },
    {
        image:allClients['europer'],
        title: 'Com. e Industrial Europer Ltda.',
        address: 'Quinta Normal, RM'
    },
    {
        image:allClients['inka_foods'],
        title: 'Com. E Imp. Estrella de David Ltda.',
        address: 'Recoleta, RM'
    },
    {
        image:allClients['dinaseg'],
        title: 'Dinaseg Ltda.',
        address: 'Santiago, RM'
    },
    {
        image:allClients['rossetti'],
        title: 'DR Limitada.',
        address: 'Pudahuel, RM'
    },
    {
        image:allClients['don_felipe'],
        title: 'Dulceria Don Felipe',
        address: 'Las Condes, RM'
    },
    {
        image:allClients['don_felipe'],
        title: 'Dulceria Don Felipe',
        address: 'La Reina, RM'
    },
    {
        image:allClients['don_felipe'],
        title: 'Dulceria Don Felipe',
        address: 'Chicureo, RM'
    },
    {
        image:allClients['don_felipe'],
        title: 'Dulceria Don Felipe',
        address: 'Huechuraba, RM'
    },
    {
        image:allClients['don_felipe'],
        title: 'Dulceria Don Felipe',
        address: 'Vitacura, RM'
    },
    {
        image:allClients['don_felipe'],
        title: 'Dulceria Don Felipe',
        address: 'Peñalolen, RM'
    },
    {
        image:allClients['don_felipe'],
        title: 'Dulceria Don Felipe',
        address: 'Providencia, RM'
    },
    {
        image:allClients['d_williams'],
        title: "D'Williams S.A.",
        address: 'Colina, RM'
    },
    {
        image:allClients['espura'],
        title: 'Espura Chile Spa.',
        address: 'Recoleta, RM'
    },
    {
        image:allClients['farmacia_descuento'],
        title: 'Farmacias FarmaDescuento Spa.',
        address: 'Lo Barnechea, RM'
    },
    {
        image:allClients['faset'],
        title: 'Faset Ltda.',
        address: 'Valparaiso, Valparaiso'
    },
    {
        image:allClients['el_dato'],
        title: 'Ferreteria El Dato',
        address: 'Independencia, RM'
    },
    {
        image:allClients['el_dato'],
        title: 'Ferreteria El Dato',
        address: 'Quilicura, RM'
    },
    {
        image:allClients['el_dato'],
        title: 'Ferreteria El Dato',
        address: 'Huechuraba, RM'
    },
    {
        image:allClients['el_dato'],
        title: 'Ferreteria El Dato',
        address: 'Colina, RM'
    },
    {
        image:allClients['gepa'],
        title: 'Taller Automotriz Gepa Ltda.',
        address: 'Conchali, RM'
    },
    {
        image:allClients['forestal_lotito'],
        title: 'Forestal Lotito y Cia. Ltda.',
        address: 'Santo Domingo, San Antonio'
    },
    {
        image:allClients['ferreteria_santa_blanca'],
        title: 'Ferreteria Santa Blanca',
        address: 'Casablanca, Valparaiso'
    },
    {
        image:allClients['lotito'],
        title: 'Ferreteria Lotito Ltda.',
        address: 'Santo Domingo, San Antonio'
    },
    {
        image:allClients['karry'],
        title: 'Ingen S.A.',
        address: 'Las Condes, RM'
    },
    {
        image:allClients['tress'],
        title: 'Importadora tress S.A.',
        address: 'Macul, RM'
    },
    {
        image:allClients['ks_maestro'],
        title: 'KS Maestro Ltda.',
        address: 'Santiago, RM'
    },
    {
        image:allClients['manpower'],
        title: 'Manpower de Chile S.A.',
        address: 'Santiago, RM'
    },
    {
        image:allClients['gal'],
        title: 'Metalmecanica Gal Spa.',
        address: 'Independencia, RM'
    },
    {
        image:allClients['modulos_sa'],
        title: 'Modulos S.A.',
        address: 'Lampa, RM'
    },
    {
        image:allClients['moto_aventura'],
        title: 'MotoAventura Spa.',
        address: 'Osorno, Osorno'
    },
    {
        image:allClients['motouring'],
        title: 'Motouring Chile Ltda.',
        address: 'Las Condes, RM'
    },
    {
        image:allClients['rienzi'],
        title: 'Neumaticos Rienzi Ltda.',
        address: 'Estacion Central, RM'
    },
    {
        image:allClients['novadist'],
        title: 'NovaDist Ltda.',
        address: 'Santiago, RM'
    },
    {
        image:allClients['la_mendez'],
        title: 'Pasteleria La Mendez Ltda.',
        address: 'Las Condes, RM'
    },
    {
        image:allClients['katto'],
        title: 'Piedra Molino Ltda.',
        address: 'Recoleta, RM'
    },
    {
        image:allClients['pdi'],
        title: 'Policia de Investigaciones de Chile',
        address: 'Santiago, Santiago'
    },
    {
        image:allClients['pronorte'],
        title: 'ProNorte Ltda.',
        address: 'Copiapo, Copiapo'
    },
    {
        image:allClients['prosegur'],
        title: 'Prosegur',
        address: 'Renca, RM'
    },
    {
        image:allClients['farmacia_victoria'],
        title: 'Soc. Com. Olivia Haydee Pasten y Cia.',
        address: 'Tocopilla, Antofagasta'
    },
    {
        image:allClients['dimatec'],
        title: 'Dimatec Ltda.',
        address: 'Constitucion, Maule'
    },
    {
        image:allClients['aridos_biobio'],
        title: 'Aridos Bio Bio Ltda.',
        address: 'Los Angeles, BioBio'
    },
    {
        image:allClients['don_bosco'],
        title: 'Maestranza Don Bosco',
        address: 'Los Angeles, BioBio'
    },
    {
        image:allClients['riegofert'],
        title: 'Riegofert Spa.',
        address: 'Las Cabras, Lib. Gral. Bernardo Ohiggins'
    },
    {
        image:allClients['ferreteria_esmeralda'],
        title: 'Ferreteria Esmeralda Ltda.',
        address: 'Las Cabras, Lib. Gral. Bernardo Ohiggins'
    },
    {
        image:allClients['filtros_montt'],
        title: 'FiltrosMontt Ltda.',
        address: 'Puerto Montt, Los Lagos'
    }
]
export function Clientes(){

    return <div className='containerClientes'>
        <TitleDescription title='Clientes - Dimasoft'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <div className='clientes'>
            <h1>Clientes</h1>
            <div className='line gradient line-medium' data-aos="fade-right"></div>
            <div className='clientes-content'>
                {clientes.map(item=><ClientCard content={item} key={clientes.indexOf(item)} />)}
            </div>
        </div>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}

function ClientCard({content}){
    return <div className='client-card-box' data-aos="zoom-in">
        <div className='client-card'>
            <img src={content.image} alt={content.title}/>
            <p className='client-card-title'>{content.title}</p>
            <p className='client-card-address'>{content.address}</p>
        </div>
    </div>
}
