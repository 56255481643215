import './software.css'
import { Footer} from '../Footer/Footer'
import { FirstHeader, SecondHeader} from '../Header/Headers'
import { LeftButtons } from '../LeftButtons/ContactButtons'
import { GFerreterias } from './GestionFerreterias/GFerreterias'
import { GComercial } from './GestionComercial/GComercial'
import { FacturaElectronica } from './FacturaElectronica/FacturaElectronica'
import { Remuneraciones } from './Remuneraciones/Remuneraciones'

import { Link, ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../MetaTags/TitleDescriptions';

export function Software(){
    return <div className='containerSoftware'>
        <TitleDescription title='Softwares. Sistemas de Gestion, Contabilidad y Facturacion - Dimasoft' description='Software ERP Chile. Sistema de Remuneraciones, Factura Electrónica, ERP Gestion Ferreterias, Boleta Electrónica, Sistema de Gestión Comercial, ERP Chile'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <h1>Software</h1>
        <div className='line line-dark'></div>
        <div className='software-content'>
            <Link to="/erp-software-dimasoft-gestion-ferreterias-chile"><h1>ERP - Gestión Ferreterías v7.1</h1></Link>
            <GFerreterias/>
        </div>
        <div className='line line-dark'></div>
        <div className='software-content'>
            <Link to="/erp-software-dimasoft-gestion-comercial-chile"><h1>ERP - Gestión Comercial v7.1</h1></Link>
            <GComercial/>
        </div>
        <div className='line line-dark'></div>
        <div className='software-content'>
            <Link to="/software-factura-electronica-dimasoft-chile"><h1>Factura Electrónica</h1></Link>
            <FacturaElectronica/>
        </div>
        <div className='line line-dark'></div>
        <div className='software-content'>
            <Link to="/software-remuneraciones-dimasoft-chile"><h1>Remuneraciones v8.0</h1></Link>
            <Remuneraciones/>
        </div>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}
