import './servicios.css'
import { Footer} from '../Footer/Footer'
import { FirstHeader, SecondHeader} from '../Header/Headers'
import { LeftButtons } from '../LeftButtons/ContactButtons'
import { Capacitaciones } from './Capacitaciones/Capacitaciones'
import { ContableAuditoria } from './ContableAuditoria/ContableAuditoria'
import { ServiciosTI } from './ServiciosTI/ServiciosTI'
import { Link, ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../MetaTags/TitleDescriptions';


export function Servicios(){
    return <div className='containerServicios'>
        <TitleDescription title='Servicios Contables, Tecnologicos y Capacitaciones - Dimasoft' description='Conoce nuestros servicios de Capacitación, TI y Auditoria Contable. Contamos con un Centro de Capacitación especializado de nuestros Softwares porque queremos que nuestros usuarios y clientes estén en constante capacitación sobre las funcionalidades de nuestras soluciones.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <h1>Servicios</h1>
        <div className='line line-dark'></div>
        <div className='servicios-content'>
            <Link to="/capacitaciones-dimasoft-chile"><h1>Capacitaciones</h1></Link>
            <Capacitaciones/>
        </div>
        <div className='line line-dark'></div>
        <div className='servicios-content'>
            <Link to="/servicio-contable-y-auditoria"><h1>Servicio Contable y Auditoria</h1></Link>
            <ContableAuditoria/>
        </div>
        <div className='line line-dark'></div>
        <div className='servicios-content'>
            <Link to="/servicios-ti-dimasoft-chile"><h1>Servicios TI</h1></Link>
            <ServiciosTI/>
        </div>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}
