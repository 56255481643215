import './capacitaciones.css'
import { Link } from "react-router-dom";
import { Footer} from '../../Footer/Footer'
import { FirstHeader, SecondHeader} from '../../Header/Headers'
import { LeftButtons} from '../../LeftButtons/ContactButtons'
import image1 from '../../../../Others/contacto-dimasoft.jpg'

import { ScrollRestoration } from "react-router-dom";
import { TitleDescription } from '../../MetaTags/TitleDescriptions';


export function CapacitacionesR(){
    return <div className='containerCapacitaciones'>
        <TitleDescription title='Capacitaciones en Software, Sistemas y ERPs - Dimasoft' description='Nuestro servicio de capacitación en el uso de nuestros Softwares tiene como objetivo mejorar la gestion administrativa de tu negocio.'/>
        <FirstHeader/>
        <SecondHeader/>
        <LeftButtons/>
        <h1>Capacitaciones</h1>
        <Capacitaciones/>
        {/* <Footer/> */}
        <ScrollRestoration />
    </div>
}
export function Capacitaciones(){
    return <div className='capacitaciones'>
        <div className='line gradient line-small' data-aos="fade-right"></div>
        <div className='g-content-flex'>
            <div className='g-content-left' data-aos="fade-right">
                <p>El servicio de capacitación en el uso de las Soluciones de Dimasoft, está dirigido a mejorar la gestión administrativa de su negocio o empresa, y se orienta a capacitar y facultar a los usuarios de las aplicaciones para obtener el máximo de rendimiento de su inversión en todas las funcionalidades de nuestros sistemas.</p>
                <p>Para más información acerca de este servicio, por favor haga click en el siguiente <Link className='enlace-capacitacion-contacto' to='/contacto'>enlace</Link>.</p>
            </div>
            <div className='g-content-right'>
                <img src={image1} alt="" />
            </div>
        </div>
        <div className='g-content content-center'>
            <h2 className='span-contact'>Contáctenos</h2>
            <div className='line gradient line-small' data-aos="fade-right"></div>
            <p>Solicite más información, conversemos de su negocio...</p>
            <Link data-aos="fade-right" to="/contacto"><button className='secondary-button'>Contáctenos</button></Link>
        </div>
    </div>
}