import './ventajas.css'
export function Ventajas(){
    return <div className="containerVentajas">
        <div className='ventajas'  data-aos="fade-right">
            <p className="ventajas-title">Ventajas de trabajar con nuestra empresa</p>
            <div className='ventajas-linea'></div>
            <div className='ventajas-text'>
                <p>1.<span> Soporte técnico: </span>¿Tienes algun problema? No te preocupes. Nosotros lo solucionamos.</p>
                <p>2.<span> Actualizaciones periódicas: </span>Todos los sistemas desarrollados por nosotros se actualizan constantemente.</p>
                <p>3.<span> Mejor precio del mercado: </span>Actualmente contamos con el mejor precio del mercado, asequible para cualquier empresa.</p>
                <p>4.<span> Descuentos: </span>Descuentos por licencias adicionales del mismo Producto.</p>
            </div>
        </div>
    </div>
}