import './socios.css'
import bixolon from '../../../../Socios/bixolon-dimasoft.webp'
import one from '../../../../Socios/one-dimasoft.webp'
import aws from '../../../../Socios/aws-dimasoft.webp'
import google_cloud from '../../../../Socios/google-cloud-dimasoft.webp'

const socios = [    
    {
        name: 'bixolon',
        img: bixolon,
        website: ''
    },
    {
        name: 'one',
        img: one,
        website: ''
    },
    {
        name: 'amazon-ws',
        img: aws,
        website: 'https://aws.amazon.com/es/'
    },
    {
        name: 'google-cloud',
        img: google_cloud,
        website: 'https://cloud.google.com/'
    }
]

export function Socios(){

    return <div className="containerSocios">
        <div className='socios'>
            <p className="socios-title">Socios Tecnologicos</p>
            <div className='linea' data-aos="fade-right"></div>
            <div className='socios-image' data-aos="fade-up">
                {socios.map((item, index)=> item.website?
                    <a href={item.website} target='_blank' key={index}>
                        <img src={item.img} alt={item.name} style={item.website=='https://cloud.google.com/'?{width:'160px',height:'100px',padding:'10px 10px'}:{}}/>
                    </a>:
                    <img src={item.img} key={index} alt={item.name}/>
                )}
            </div>
        </div>
    </div>
}