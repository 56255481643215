import { FaCheck, FaPhoneAlt, FaWhatsapp, FaRegEnvelope} from 'react-icons/fa'
import './slider.css'
import { Link } from "react-router-dom";


import {Slideshow, Slide } from './Slideshow'

import puntoVentaImage from '../../../../Slider/punto-de-venta-dimasoft.webp'
import compraOnlineImage from '../../../../Slider/compra-online-erp-dimasoft.webp'
import gestionImage from '../../../../Slider/gestion-erp-dimasoft.webp'
import balanceImage from '../../../../Slider/balance-erp-dimasoft.webp'
import writingImage from '../../../../Slider/escribiendo-erp-dimasoft.webp'
import ferreteriaImage from '../../../../Slider/ferreteria-dimasoft.webp'
import erpImage from '../../../../Slider/software-erp-dimasoft.webp'

export function Slider(){

    const slider = [
        {
            image: puntoVentaImage, 
            text:{
                normal:'Software para Punto de Venta y ',
                strong:'Boleta Electrónica'
            },
            list:[],
            button: SliderButton('/software-pos-advance-chile','Ver Detalles'),
            class: 'punto-venta',
            probabilitie:2
        },
        {
            image: erpImage, 
            text:{
                normal:'Software para Empresas ',
                strong:'ERP Gestión Comercial'
            },
            list:[],
            button: SliderButton('/erp-software-dimasoft-gestion-comercial-chile','Ver Detalles'),
            class: 'erp',
            probabilitie:2
        },
        {
            image: ferreteriaImage, 
            text:{
                normal:'Software para Ferreterias ',
                strong:'ERP Gestión Ferreterias'
            },
            list:[],
            button: SliderButton('/erp-software-dimasoft-gestion-ferreterias-chile','Ver Detalles'),
            class: 'ferreteria',
            probabilitie:2
        },
        {
            image: compraOnlineImage, 
            text:{
                normal:'Sistema de ',
                strong:'E-commerce'
            },
            list:[
                'Integrado 100% con ERP Gestión Comercial',
                'Sincronización Automática de Productos, Stock, Clientes y más.',
                'Integración con Webpay Plus y MercadoPago'
            ],
            button: SliderButton('/contacto','Contáctenos',<FaRegEnvelope/>),
            class: 'ecommerce',
            probabilitie:2
        },
        {
            image: gestionImage, 
            text:{
                normal:'Le recordamos nuestros canales de comunicación disponibles',
                strong:''
            },
            list:<>
                <li ><FaPhoneAlt /> +56 22 205 1018 Mesa Central</li>
                <li ><FaWhatsapp /> +56 9 8294 5099 Voz / WhatsApp</li>
                <li ><FaRegEnvelope /> info@dimasoft.cl / soporte@dimasoft.cl</li>
            </>,
            button:null,
            class: 'canales',
            probabilitie:0
        },
        {
            image: balanceImage, 
            text:{
                normal:'Soluciones en Software que su negocio necesita',
                strong:''
            },
            list:[],
            button: SliderButton('/contacto','Contáctenos',<FaRegEnvelope/>),
            class: 'soluciones',
            probabilitie:1
        },
        {
            image: writingImage, 
            text:{
                normal:'Cámbiese a la Factura Electrónica con ',
                strong:'Dimasoft'
            },
            list:[
                'Es rápido y simple...',
                'Sin costos mensuales por emisión',
            ],
            button: SliderButton('/contacto','Contáctenos',<FaRegEnvelope/>),
            class: 'factura-electronica',
            probabilitie:2
        }
    ]

    let mixedSlider = arrayMixer(slider)

    return <div className="slider">
        <Slideshow>
            {
            mixedSlider.map((item, index) =>
                <Slide key={index}>
                    <img src={item.image} alt="image-slider" loading={index==0?"":"lazy"}/>
                    <div className="slider-content">
                        <div className={'slider-content-text slider-text-'+item.class}>
                            <span>{item.text.normal}</span>
                            <span><strong>{item.text.strong}</strong></span>
                            <ul className="lista">
                                {item.class=='canales'?item.list: 
                                    item.list.map(
                                        (lista,i) => <li key={i}><FaCheck/> {lista}</li>
                                    )
                                }
                            </ul>
                            {item.button}
                        </div>
                    </div>
                </Slide>
            )
            }
            
        </Slideshow>
    </div>
}

const SliderButton = (to,text,icon=null)=>{
    return <Link to={to}>
        <button className='slider-content-button'>
            {text} {icon}
        </button>
    </Link>
}

const arrayMixer = (array)=>{
    let probabilities = array.flatMap((item, index) => Array(item.probabilitie).fill(index))
    let flag = Math.floor(Math.random() * probabilities.length)

    let first_array = array.slice(0, probabilities[flag])
    let second_array = array.slice(probabilities[flag])
    let response = second_array.concat(first_array)

    return response
}